
import mobileScroll from '@/mixins/mobileScroll'
export default {
    mixins: [mobileScroll],

    props: {
        tabs: {
            type: Object,
            default: () => {},
        },

        value: {
            type: String,
            default: '',
        },
    },
}
