
export default {
    name: 'FeatureSection',
    props: {
        features: {
            type: Array,
            default: () => [],
        },
        textCenter: {
            type: [Boolean, Array],
            default: false,
        },
        textCenterMobile: {
            type: [Boolean, Array],
            default: false,
        },
        container: Boolean,
        reverse: Boolean,
        fixHeight: Boolean,
        slim: Boolean,
        imageTop: Boolean,
        cover: Boolean,
        gap: {
            type: String,
            default: 'gap-0',
        },
    },
    computed: {},
}
