
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    props: {
        to: {
            type: null,
            default: null,
        },
    },

    computed: {
        cover() {
            return this.model?.cover?.url || require('@/assets/images/placeholder.png')
        },
    },
}
