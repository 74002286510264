
export default {
    props: {
        value: {
            type: null,
            default: null,
        },
        disabled: Boolean,
        placeholder: {
            type: String,
            default: '',
        },
        getAddressObj: Boolean,
    },

    data() {
        return {
            address: {},
            autocompleteOptions: {},
        }
    },

    methods: {
        placeChanged(val) {
            this.$emit('change', val)
            this.$emit('input', this.getAddressObj ? val : val.formatted_address)
        },
    },
}
