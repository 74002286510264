
import mobileScroll from '@/mixins/mobileScroll'
import getCategoryUrl from '@/components/pages/articles/mixins/getCategoryUrl'

export default {
    mixins: [mobileScroll, getCategoryUrl],

    props: {
        categories: {
            type: Array,
            default: () => {
                return []
            },
        },
        contentTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
}
