export default {
    methods: {
        getUrl(category, contentTypes) {
            if (category && category.url) return category.url
            const contentType = contentTypes.find((el) => {
                return el.slug === category.slug
            })
            if (contentType?.url) return contentType.url
            return {}
        },
    },
}
