
export default {
    props: {
        content: {
            type: Object,
            default: () => {
                return {}
            },
        },
        noTabs: Boolean,
    },

    data() {
        return {
            active: null,
            modalShow: false,
            value: null,
            focused: false,
            isVendor: false,
            isLogin: false,
        }
    },

    computed: {
        tabs() {
            return this.content.tabs || {}
        },

        activeTab() {
            return this.tabs[this.activeTabSlug] || {}
        },

        activeTabSlug() {
            return this.active || this.content?.defaultTab
        },

        backgroundImage() {
            return process.browser
                ? window.innerWidth > 576
                    ? this.activeTab.backgroundImage
                    : this.activeTab.mobileBackgroundImage
                : this.activeTab.backgroundImage
        },
    },

    watch: {
        activeTabSlug() {
            this.value = null
        },
    },

    methods: {
        redirect() {
            if (this.activeTab.ga_event)
                this.$ga?.event({
                    eventCategory: this.activeTab.ga_event.eventCategory,
                    eventAction: this.activeTab.ga_event.eventAction,
                    eventLabel: this.activeTab.ga_event.eventLabel,
                    eventValue: this.activeTab.ga_event.eventValue,
                })

            if (this.activeTab.track) this.$track(this.activeTab.track)

            this.$nextTick(() => {
                this.$router.push({
                    path: this.activeTab.redirect,
                    query: { userfield: this.activeTab.userfield, value: this.value, klaviyo: this.activeTab.klaviyo },
                })
            })
        },
    },
}
