
export default {
    props: {
        content: {
            type: Object,
            default: () => {},
        },
    },

    async fetch() {
        await this.$axios
            .$get(`/api/v1/content/list`, {
                params: {
                    page: this.page,
                    content_type: 'homepage-styles',
                    per_page: 5,
                },
            })
            .then((res) => {
                this.styles = res.data
            })
            .catch(() => {
                this.styles = []
            })
    },

    data() {
        return {
            styles: [],
        }
    },
}
