
export default {
    async fetch() {
        await this.$axios
            .$get(`/api/v1/content/list`, {
                params: {
                    page: this.page,
                    content_type: 'appearances',
                    per_page: 6,
                },
            })
            .then((res) => {
                this.appearances = res.data
            })
    },

    data() {
        return {
            appearances: [],
        }
    },
}
