import { render, staticRenderFns } from "./InspirationStyles.vue?vue&type=template&id=d41d6ea0&lang=pug"
import script from "./InspirationStyles.vue?vue&type=script&lang=js"
export * from "./InspirationStyles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default})
