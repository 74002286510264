import { render, staticRenderFns } from "./CategoryList.vue?vue&type=template&id=7064c916&lang=pug"
import script from "./CategoryList.vue?vue&type=script&lang=js"
export * from "./CategoryList.vue?vue&type=script&lang=js"
import style0 from "./CategoryList.vue?vue&type=style&index=0&id=7064c916&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyCategoryItem: require('/app/components/pages/articles/CategoryItem.vue').default})
