
export default {
    props: {
        content: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            mobile: undefined,
            intActiveTool: 0,
            sliderCurrent: undefined,
            interval: null,
        }
    },

    computed: {
        activeTool: {
            get() {
                if (this.mobile) return this.sliderCurrent
                return this.intActiveTool
            },

            set(val) {
                this.intActiveTool = val
                this.sliderCurrent = val
                this.resetInterval()
            },
        },

        current() {
            return this.content && this.content.items ? this.content.items[this.activeTool] : {}
        },
    },

    mounted() {
        this.mobile = process.browser ? window.innerWidth < 992 : null
        this.activeTool = 0
    },

    beforeDestroy() {
        clearInterval(this.interval)
    },

    methods: {
        resetInterval() {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                if (this.intActiveTool === this.content.items.length - 1) this.intActiveTool = 0
                else this.intActiveTool++
            }, 30000)
        },
    },
}
